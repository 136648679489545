import { render, staticRenderFns } from "./updateWarning.vue?vue&type=template&id=bb941b24&scoped=true&"
import script from "./updateWarning.vue?vue&type=script&lang=js&"
export * from "./updateWarning.vue?vue&type=script&lang=js&"
import style0 from "./updateWarning.vue?vue&type=style&index=0&id=bb941b24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb941b24",
  null
  
)

export default component.exports