<template>
    <el-dialog title="" :visible.sync="saleDialog" width="500px" custom-class="resultDialog dataM" center
        :before-close="handleClose">
        <div class="tip">
            <img :src="warning" alt="">
            <div class="title">部分订单未更新成功</div>
            <div class="footer">
                <el-button type="primary" @click="checkReason">查看原因</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'saleError',
    data() {
        return {
            warning: require('@/assets/images/dataManage/warning.png')
        }
    },
    props: {
        saleDialog: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        checkReason() {
            this.$emit('checkReason')
            this.handleClose()
        },
        handleClose() {
            this.$emit('saleHandleClose')
        }
    },
}
</script>

<style lang="scss" scoped>
.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px 23px 0px;

        div {
            line-height: 40px;
            color: #B9B9B9;
            font-size: 16px;
        }

        .title {
            font-size: 24px;
            margin-top: 15px;
            color: #FFFFFF;
        }

        .el-button {
            color: #fff;
        }
    }
}

.footer {
    margin-top: 15px;
}
</style>